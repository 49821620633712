import { useApp } from 'AppContext'
import { Button } from 'layout/Button'
import { Container } from 'layout/Container'
import { Title } from 'layout/Title'
import { useTranslation } from 'locale/LocaleContext'
import { GetStaticProps } from 'next'
import { fetchGeneralData } from 'server/general'
import { fromModule } from 'util/styler/Styler'
import css from './Page404.module.scss'

const styles = fromModule(css)

const Page404: React.FC = () => {
    const { lang } = useApp()
    const t = useTranslation()

    return (
        <div className={styles.page404()}>
            <Container>
                <Title.H1>500 error</Title.H1>
                <p className={styles.page404.text()}>An error occured</p>
                <Button
                    to={lang ? `/${lang}` : '/'}
                    className={styles.page404.button()}
                >
                    {t.page404.btn}
                </Button>
            </Container>
        </div>
    )
}

export default Page404

export const getStaticProps: GetStaticProps = async (context) => {
    const lang = 'nl'
    const general = await fetchGeneralData(lang)

    return {
        props: {
            general
        },
		revalidate: 5 * 60
    }
}
